import React, { useState } from "react";
import "./CreditAssessService.scss";

export default function CreditAssessService({ onclickCheck }) {

    const handleClick = () => {
        onclickCheck("2");
    }

    return (
        <>
            <div className="c2c_creditAssesmentService">
                <div className="heading" style={{ display: "flex", justifyContent: "center" }}>
                    <h4 className="heading">
                        Before completing the Credit Assess service please read the following information
                    </h4>
                </div>

                <h4 className="heading">
                    Why use the Credit Assess Service ?
                </h4>

                <p className="subHeading">
                    An important factor when applying for any form of finance is your credit standing.
                    The Credit Assess service will provide you and your adviser with an assessment of how an appropriate
                    lender would view an application from you by accessing your credit score and credit file from Equifax.
                    This information will assist you and your adviser when reviewing the most
                    appropriate lender and loan scheme options available to you.
                </p>

                <h4 className="heading">
                    What is the process?
                </h4>

                <p className="subHeading">
                    By using the Next button below, you will be taken to the service Terms & Condition and Privacy Policy for your agreement.
                    You will then be taken to the Tell Us About Yourself page where you will need to check the information provided by your adviser and add to this information where necessary.
                    Once done you can use the Confirm button to start the Credit Assess process.
                    Part of this process will involve you completing a number of security questions delivered by Equifax and based on the content of your credit file to ensure that your
                    credit score and credit file is only available to you.
                    These questions are multiple choice and you will be required to answer as many questions as possible correctly.
                    Please consult any documentation that you need in order to complete these questions correctly to increase your chance of success.
                    On completion your credit score and credit file will be delivered to you in PDF format.
                    You should open this document which will allow you to print and/or store it on your local drive.
                </p>

                <h4 className="heading">
                    Will this affect my credit standing?
                </h4>

                <p className="subHeading">
                    No. There will be a record of this search on your credit file but it will only be visible to you.
                    No third party will see that this search has been undertaken and it will have no impact on your credit standing whatsoever.
                </p>

                <h4 className="heading">
                    What happens next?
                </h4>

                <p className="subHeading">
                    The Next button below will take you to the Terms & Conditions and Privacy Policy page and then onto the Tell Us About Yourself page.
                    Once completed and following the invoking of the Confirm button you should await delivery of your report. A copy of this report will also be made available to your adviser.
                </p>

                <p className="subHeading" style={{ marginTop: "10px" }}>
                    If you wish to continue please use the Next button to be taken to the Terms & Conditions page and then onto the Tell Us About Yourself page for completion.
                </p>

            </div>

            <div className="footer" style={{ justifyContent: "end" }}>
                <button className="button" onClick={() => handleClick()}>
                    Next
                </button>
            </div>

        </>

    )
}