import { actionUserdetails } from "./action";
import { UserDetails } from "./constant";

const initialValues = JSON.parse(localStorage.getItem("getDetail")) || [];

export const reducerUserDetails = (initialValue = initialValues, action) => {

    switch (action.type) {
        case UserDetails:
            return action.data;
        default:
            return initialValue;
    }
}