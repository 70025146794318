import axios from "axios";
import basicUrl from "../locale/env.json";

const baseUrl = basicUrl.baseUrl;
export const getMethod = async (url) => {
    try {
        let data = await axios({
            method: "GET",
            url: baseUrl + url,
            headers: {
                accept: "application/json",
            }
        });
        return data;
    } catch (error) {
        return error.response;
    }
};

export const postMethod = async (url, body) => {
    try {
        let data = await axios({
            method: "POST",
            url: url,
            headers: {
                accept: "application/json",
                Authorization: "token "
            },
            data: body
        });
        return data;
    } catch (error) {
        return error.response;
    }
};

export const putMethod = async (url, body) => {
    try {
        let data = await axios({
            method: "PUT",
            url: baseUrl + url,
            headers: {
                accept: "application/json",
                Authorization: "token "
            },
            data: body
        });
        return data;
    } catch (error) {
        return error.response;
    }
};

export const deleteMethod = async (url, id) => {
    try {
        let data = await axios({
            method: "DELETE",
            url: baseUrl + url,
            headers: {
                accept: "application/json",
                Authorization: "token "
            },
            data: id
        })
        return data;
    } catch (error) {
        return error.response;
    }
}