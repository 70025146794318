import React from "react";
import { BrowserRouter as Routers, Navigate, Route, useLocation, Routes, } from "react-router-dom";
import ProceedView from "../../screen/proceedView/ProceedView";
import ThankYou from "../../screen/thankYou/ThankYou";
import OtpVerification from "../../screen/otp/otpVerification";
import TemplateView from "../../screen/templateView/TemplateView";

export default function Rout() {
    return (
        <>
            <Routers>
                <Routes>
                    <Route path="/proceedView" element={<ProceedView />} />
                    <Route path="/thankYou" element={<ThankYou />} />
                    <Route path="/templateView" element={<TemplateView />} />
                    <Route path="/" element={<TemplateView />} />
                    <Route path="/:token" element={<TemplateView />} />
                </Routes>
            </Routers>
        </>
    );
}
