import React from 'react';
import Rout from './routes/router/router';
import { Provider } from "react-redux";
import store from "./redux/store";
 
export default function App() {
  return (
    <>
      <Provider store={store}>
        <Rout /> 
      </Provider>
    </>
  );
}