import React, { useEffect, useState } from "react";
import { Row, Col, Input, Radio, Form } from 'antd';
import { DatePicker, Space, Select } from 'antd';
import "./ConfirmDetails.scss";
import { useSelector } from "react-redux";
import url from "../../../utils/services/url.json";
import { getMethod,postMethod } from "../../../utils/services/api";
import dayjs from 'dayjs';
import { RotatingLines } from 'react-loader-spinner';
export default function ConfirmDetails() {
    
    const hostname = window.location.origin;

    const { Option } = Select;
    const [form] = Form.useForm();
    const [titleList, setTitleList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [responseData, setResponseData] = useState(
        {
        client:'',
        externalRef : '',
        firstname : '',
        lastName : '',
        DateOfBirth: '',
        email : '',
        telephone: '',
        PostCode:'',
        AddressLine1: '',
        AddressLine2: '',
        PostTown: '',
        completeUrl:''
        
    });
    
    const onFinish = async (value) => {
        setLoader(true)
        const formDataToSend = new FormData();
        // Populate formDataToSend with your form data
        for (const key in responseData) {
        formDataToSend.append(key, responseData[key]);
        }
        try {
            const url = 'https://signup.consents.online/api/token/redirect'; 
            const response = await postMethod(url, formDataToSend); 
            const requestURL = response.request.responseURL;
            window.location.href = requestURL;
          } 
          catch (error) {
            console.error('Error:', error);
          }
    };
    const result = useSelector((state) => state.reducerUserDetails);
    useEffect(() => {
        getTitleList();
        if((result?.data?.leadCreditKudos && !result?.data?.leadCreditScore) || (result?.data?.leadCreditKudos && result?.data?.leadCreditScore)){
            var client = 'click2check2'
        }else{
            var client = 'click2checkid'
        }
        let data={
            client:'click2check-dev',
            externalRef : result?.data?.uid,
            firstname : result.data?.firstName,
            lastName : result?.data?.lastName,
            DateOfBirth: result?.data?.dob,
            email : result?.data?.email, 
            telephone: result?.data?.mobilePhone,
            PostCode:result?.data?.currentPostCode,
            AddressLine1: result?.data?.currentAddress1,
            AddressLine2: result?.data?.currentAddress2,
            PostTown: result?.data?.currentCity,
            completeUrl:hostname + "/thankYou"
        }
        setResponseData(data)
    }, [])

    const getTitleList = async () => {
        const result = await getMethod(url.titleConfirmDetails);
        setTitleList(result?.data?.results); 
    }
    return (
        <>
            <Form form={form} layout="vertical" initialValues={{
                'Title' : result?.data?.title,
              'First Name': result?.data?.firstName,
              'Surname' :result?.data?.lastName,
              'Date of Birth': dayjs(result?.data?.dob, 'YYYY-MM-DD'),
              'Gender' : result?.data?.gender,
             'Email Address':result?.data?.email,
             'Mobile Number':result?.data?.mobilePhone,
             'Post Code':result?.data?.currentPostCode,
             'House Name or Number':result?.data?.currentAddress1,
            '2nd Line of address':result?.data?.currentCity,
            'Time At Address':result?.data?.timeAtAddressYear,
            'Previous Addresses': result?.data?.previousAddress.map((item) => ({
                    'Post Code': item.currentPostCode,
                    'House Name or Number': item.currentAddress1,
                    '1st Line of address': item.currentAddress2,
                    '2nd Line of address': item.currentCity,
                    'County': item.currentCounty,
                    'Country': item.currentCountry,
                    'Time At Address': item.timeAtAddressYear
            }))

    // Other initial values for your form fields
  }}>
                <div className="c2c_confirmDetails">
                    <div style={{ width: "100%" }}>
                        <p className="heading">
                            <strong>Basic Information</strong>
                        </p>
                    </div>

                    <Row style={{ width: "100%", marginTop: "40px" }}>
                        <Col md={4} sm={4} xs={4} style={{ paddingRight: "10px" }}>
                            <Form.Item name="Title" label="Title" rules={[{ required: true }]}>
                                <Select
                                    style={{ width: "100%" }}
                                    name="title"
                                    defaultValue={result?.data?.title}
                                    placeholder="Title"
                                    optionFilterProp="children"
                                    disabled={true}
                                >
                                    {titleList.map((key, idx) => (
                                        <Option value={key.id}>{key.dropDownValue}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col md={6} sm={6} xs={6} style={{ paddingRight: "10px", paddingLeft: "10px" }}>
                            <Form.Item name="First Name" label="First Name" rules={[{ required: true }]}>
                                <Input name="first_name" className="input" defaultValue={result.data?.firstName} disabled />
                            </Form.Item>
                        </Col>

                        <Col md={6} sm={6} xs={6} style={{ paddingRight: "10px", paddingLeft: "10px" }}>
                            <Form.Item name="Middle Name" label="Middle Name">
                                <Input name="middle_name" className="input" defaultValue={result?.data?.middleName} disabled />
                            </Form.Item>
                        </Col>

                        <Col md={6} sm={6} xs={6} style={{ paddingLeft: "10px" }}>
                            <Form.Item name="Surname" label="Surname" rules={[{ required: true }]}>
                                <Input name="sur_name" className="input" defaultValue={result?.data?.lastName} disabled />
                            </Form.Item>
                        </Col>
                    </Row>


                    <Row style={{ width: "100%", marginTop: "20px" }}>
                        <Col md={6} sm={6} xs={6} style={{ paddingRight: "20px" }}>
                            <Form.Item name="Date of Birth" label="Date of Birth" rules={[{ required: true }]}>
                                <DatePicker
                                    name="birth_date"
                                    allowClear={false}
                                    defaultValue={dayjs(result?.data?.dob, 'YYYY-MM-DD')}
                                    value={dayjs(result?.data?.dob, 'YYYY-MM-DD')}
                                    placeholder="YYYY-MM-DD"
                                    disabled
                                />
                            </Form.Item>
                        </Col>

                        <Col md={18} sm={18} xs={18} style={{ paddingLeft: "20px" }}>
                            <Form.Item name="Gender" label="Gender" rules={[{ required: true }]}>
                                <Radio.Group name="radiogroup" className="RadioButton" defaultValue={result?.data?.gender} disabled>
                                    <Radio value={1} >Male</Radio>
                                    <Radio value={2}>Female</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>

                    <div style={{ width: "100%", marginTop: "40px" }}>
                        <p className="heading">
                            <strong>Contact Details</strong>
                        </p>
                    </div>

                    <Row style={{ width: "100%", marginTop: "40px" }}>
                        <Col md={8} sm={8} xs={8} style={{ paddingRight: "10px" }}>
                            <Form.Item name="Email Address" label="Email Address" rules={[{ required: true }]}>
                                <Input name="email_address" className="input" defaultValue={result?.data?.email} disabled />
                            </Form.Item>
                        </Col>

                        <Col md={8} sm={8} xs={8} style={{ paddingRight: "10px", paddingLeft: "10px" }}>
                            <Form.Item name="Home Phone Number" label="Home Phone Number">
                                <Input name="home_phone_number" className="input" defaultValue={result?.data?.homePhone} disabled />
                            </Form.Item>
                        </Col>

                        <Col md={8} sm={8} xs={8} style={{ paddingLeft: "10px" }}>
                            <Form.Item name="Mobile Number" label="Mobile Number" rules={[{ required: true }]}>
                                <Input name="mobile_number" className="input" defaultValue={result?.data?.mobilePhone} disabled />
                            </Form.Item>
                        </Col>
                    </Row>

                    <div style={{ width: "100%", marginTop: "40px" }}>
                        <p className="headingAddress">
                            <strong style={{ color: "#313131", fontWeight: "700",fontFamily:" c2c-roboto-regular"}}>Address </strong>
                            (Enter your home postcode below and press the find button. You can choose your address from the options presented to you )
                        </p>
                    </div>

                    <Row style={{ width: "100%", marginTop: "40px" }}>
                        <Col md={8} sm={8} xs={8} style={{ paddingRight: "10px" }}>
                            <Form.Item name="Post Code" label="Post Code" rules={[{ required: true }]}>
                                <Input name="post_code" className="input" defaultValue={result?.data?.currentPostCode} disabled />
                            </Form.Item>
                        </Col>

                        <Col md={6} sm={6} xs={6} style={{ paddingLeft: "10px", alignItems: "end", display: "flex" }}>
                            <button className="button" disabled>
                                Find
                            </button>
                        </Col>
                    </Row>

                    <Row style={{ width: "100%", marginTop: "20px" }}>
                        <Col md={8} sm={8} xs={8} style={{ paddingRight: "10px" }}>
                            <Form.Item name="House Name or Number" label="House Name or Number" rules={[{ required: true }]}>
                                <Input name="house_name_number" className="input" defaultValue={result?.data?.currentAddress1} disabled />
                            </Form.Item>
                        </Col>

                        <Col md={8} sm={8} xs={8} style={{ paddingRight: "10px", paddingLeft: "10px" }}>
                            <Form.Item name="1st Line of address" label="1st Line of address">
                                <Input name="1st_line_address" className="input" defaultValue={result?.data?.currentAddress2} disabled />
                            </Form.Item>
                        </Col>

                        <Col md={8} sm={8} xs={8} style={{ paddingLeft: "10px" }}>
                            <Form.Item name="2nd Line of address" label="2nd Line of address" rules={[{ required: true }]}>
                                <Input name="2nd_line_address" className="input" defaultValue={result?.data?.currentCity} disabled />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ width: "100%", marginTop: "20px" }}>
                        <Col md={8} sm={8} xs={8} style={{ paddingRight: "10px" }}>
                            <Form.Item name="County" label="County">
                                <Input name="county" className="input" defaultValue={result?.data?.currentCounty} disabled />
                            </Form.Item>
                        </Col>

                        <Col md={8} sm={8} xs={8} style={{ paddingRight: "10px", paddingLeft: "10px" }}>
                            <Form.Item name="Country" label="Country">
                                <Input name="coutry" className="input" defaultValue={result?.data?.currentCountry} disabled />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ width: "100%", marginTop: "20px" }}>
                        <Col md={8} sm={8} xs={8} style={{ paddingRight: "10px" }}>
                            <Form.Item name="Time At Address" label="Time At Address" rules={[{ required: true }]}>
                                <Row>
                                    <Col md={12} sm={12} xs={12} style={{ paddingRight: "10px" }}>
                                        <Select
                                            style={{ width: "100%" }}
                                            name="time_at_address"
                                            defaultValue={result?.data?.timeAtAddressYear}
                                            optionFilterProp="children"
                                            disabled={true}
                                        >
                                            <Option value={result?.data?.timeAtAddressYear}>{result?.data?.timeAtAddressYear}</Option>
                                        </Select>
                                    </Col>
                                    <Col md={12} sm={12} xs={12} style={{ paddingLeft: "10px" }}>
                                        <Select
                                            style={{ width: "100%" }}
                                            name="time_at_address"
                                            defaultValue={result?.data?.timeAtAddressMonth}
                                            optionFilterProp="children"
                                            disabled={true}
                                        >
                                            <Option value={result?.data?.timeAtAddressMonth}>{result?.data?.timeAtAddressMonth}</Option>
                                        </Select>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>
                    </Row>

                    {result?.data?.previousAddress.length>=1 && 
                      <>
                        {result?.data?.previousAddress.map((item,index) => {
                            return (
                                <>
                                <div style={{ width: "100%", marginTop: "40px" }}>
                                    {index==0 ? <p className="headingAddress">
                                        <strong style={{ color: "#313131", fontWeight: "700",fontFamily:" c2c-roboto-regular"}}>Previous Address </strong>
                                        (If time at current address is less than 3 years)
                                    </p> : <p className="headingAddress">
                                        <strong style={{ color: "#313131", fontWeight: "700",fontFamily:" c2c-roboto-regular"}}>Additional Previous Address </strong>
                                        (If combined time at current address and previous address is less than 3 years)
                                    </p>}
                                   
                                </div>
                                <Row style={{ width: "100%", marginTop: "40px" }}>
                                    <Col md={8} sm={8} xs={8} style={{ paddingRight: "10px" }}>
                                        <Form.Item  name={['Previous Addresses', index, 'Post Code']} label="Post Code" rules={[{ required: true }]}>
                                            <Input name="post_code" className="input" defaultValue={item.currentPostCode} disabled />
                                        </Form.Item>
                                    </Col>

                                    <Col md={6} sm={6} xs={6} style={{ paddingLeft: "10px", alignItems: "end", display: "flex" }}>
                                        <button className="button" disabled>
                                            Find
                                        </button>
                                    </Col>
                                </Row>
                                <Row style={{ width: "100%", marginTop: "20px" }}>
                                    <Col md={8} sm={8} xs={8} style={{ paddingRight: "10px" }}>
                                        <Form.Item name={['Previous Addresses', index, 'House Name or Number']} label="House Name or Number" rules={[{ required: true }]}>
                                            <Input name="house_name_number" className="input" defaultValue={item.currentAddress1} disabled />
                                        </Form.Item>
                                    </Col>

                                    <Col md={8} sm={8} xs={8} style={{ paddingRight: "10px", paddingLeft: "10px" }}>
                                        <Form.Item name={['Previous Addresses', index, '1st Line of address']} label="1st Line of address">
                                            <Input name="1st_line_address" className="input" defaultValue={item.currentAddress2} disabled />
                                        </Form.Item>
                                    </Col>

                                    <Col md={8} sm={8} xs={8} style={{ paddingLeft: "10px" }}>
                                        <Form.Item name={['Previous Addresses', index, '2nd Line of address']} label="2nd Line of address" rules={[{ required: true }]}>
                                            <Input name="2nd_line_address" className="input" defaultValue={item.currentCity} disabled />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row style={{ width: "100%", marginTop: "20px" }}>
                                    <Col md={8} sm={8} xs={8} style={{ paddingRight: "10px" }}>
                                        <Form.Item name={['Previous Addresses', index, 'County']} label="County">
                                            <Input name="county" className="input" defaultValue={item.currentCounty} disabled />
                                        </Form.Item>
                                    </Col>

                                    <Col md={8} sm={8} xs={8} style={{ paddingRight: "10px", paddingLeft: "10px" }}>
                                        <Form.Item name={['Previous Addresses', index, 'Country']} label="Country">
                                            <Input name="coutry" className="input" defaultValue={item.currentCountry} disabled />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row style={{ width: "100%", marginTop: "20px" }}>
                                    <Col md={8} sm={8} xs={8} style={{ paddingRight: "10px" }}>
                                        <Form.Item name={['Previous Addresses', index, 'Time At Address']} label="Time At Address" rules={[{ required: true }]}>
                                            <Row>
                                                <Col md={12} sm={12} xs={12} style={{ paddingRight: "10px" }}>
                                                    <Select
                                                        style={{ width: "100%" }}
                                                        name="time_at_address"
                                                        defaultValue={item.timeAtAddressYear}
                                                        optionFilterProp="children"
                                                        disabled={true}
                                                    >
                                                        <Option value={item.timeAtAddressYear}>{item.timeAtAddressYear}</Option>
                                                    </Select>
                                                </Col>
                                                <Col md={12} sm={12} xs={12} style={{ paddingLeft: "10px" }}>
                                                    <Select
                                                        style={{ width: "100%" }}
                                                        name="time_at_address"
                                                        defaultValue={item.timeAtAddressMonth}
                                                        optionFilterProp="children"
                                                        disabled={true}
                                                    >
                                                        <Option value={item.timeAtAddressMonth}>{item.timeAtAddressMonth}</Option>
                                                    </Select>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                </>
                            );
                        })}
                        
                      </> 
                    }

                    
                </div>

                <Row style={{ width: "100%",justifyContent: "end" }} className="footer">
                    <button className="button" onClick={onFinish}>
                        Confirm
                    </button>
                </Row>
            </Form>
            {loader &&
                <div style={{position: "absolute",top: "35%",right: "50%"}} >
                    <RotatingLines
                        strokeColor="black"
                        strokeWidth="3"
                        animationDuration="1.75"
                        width="96"
                        visible={true}
                    />
                </div>
            }
        </>
    )
}

