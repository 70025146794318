import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from 'antd';
import "./ProceedView.scss";
import { RotatingLines } from 'react-loader-spinner';
import url from "../../utils/services/url.json";
import { getMethod } from "../../utils/services/api";
export default function Step1() {

    const navigate = useNavigate();
    const [loader, setLoader] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [caseDetail,setCaseDetail]=useState()
    const[proceedMsg,setProceedMsg]=useState(false)
    const[detailData,setDetailData]=useState()
    const[status,setStatus]=useState(true)
    setTimeout(() => { setLoader(false) }, 2000);
    useEffect(() => {
        getData();
    }, []);
    const getData = async () => {
        let data =localStorage.getItem('getDetail')
        data=JSON.parse(data)
        setDetailData(data)
        let cases=localStorage.getItem("caseDetail")
        cases=JSON.parse(cases)
        setCaseDetail(cases)
        if(cases&&cases.data.success===true){
            setStatus(true)
        }else if(cases&&cases.data.success===false){
            setStatus(false)
        }else if(!cases){
            setStatus(true)
        }
     }

    const handleClick = async() => {
        if(caseDetail){
            const result = await getMethod(url.proceed + caseDetail.data.uid);
            if(result.status===200){
                const pdfData = result.request.responseURL
                window.open(pdfData);
            }
        }
        else{
            setProceedMsg(true)
            setTimeout(() => {
                setProceedMsg(false)
              }, 3000)
        }
    }
    const handleMsg = async() => {
        setProceedMsg(true)
        setTimeout(() => {
            setProceedMsg(false)
          }, 3000)
    }
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="c2c_poceedView">
            <div className="subContainer">

                <div className="proceedView">
                    <div className="box">

                        {/* <div style={{ display: "flex", justifyContent: "end", cursor: "pointer" }}>
                            <img src={"https://uat-front.click2check.com/images/c2c-help-icon.png"} onClick={() => showModal()} />
                        </div> */}

                        <div className="heading">
                            <h2 className="title">{detailData ? `${detailData.data.firstName} ${detailData.data.lastName}` : ''}</h2>
                        </div>
                        {status ? <div className="heading">
                            <p className="text"> You have <strong>sucessfully</strong> completed the {detailData ? (detailData.data.leadCreditScore && !detailData.data.leadCreditKudos)?'identity checks':(!detailData.data.leadCreditScore && detailData.data.leadCreditKudos)?'Online Bank identity check':'Identity and Open Banking checks':''}</p>
                        </div> : <div className="heading">
                            <p className="text">  Sorry Unfortunately you have <strong>not successfully</strong> completed the identity checks.</p>
                        </div>}
                        
                        {status ? <div className="heading">
                        </div> : <div className="heading">
                            <p className="text"> The reason for this is you did not manage to answer enough questions correctly from your individual Credit report held by Equifax.</p>
                        </div>}
                        {status ? <div className="heading">
                        </div> : <div className="heading">
                            <p className="text"> Remember – It is important to have the right documentation to hand in order to succeed. You only need 4 out of the 6 questions correct to pass.</p>
                        </div>}
                        <div className="heading">
                            <p className="text"> By pressing <span style={{ color: "#1acbb1",fontWeight:"600" }}>Proceed</span> button below</p>
                        </div>
                        {status && status===true?<div className="heading">
                            <ul>
                                <li>
                                    <p className="textItem">
                                        {detailData ?  (detailData.data.leadCreditScore && !detailData.data.leadCreditKudos)?'Your advisor will recieve a copy of your report directly to the advisers Credit Assess Hub.': (!detailData.data.leadCreditScore && detailData.data.leadCreditKudos)?'Your advisor will recieve a copy of bank statements directly to the advisors Credit Assess Hub.':'Your advisor will recieve a copy of your Credit and Bank reports directly to their Credit Assess hub to view.':''}
                                       
                                    </p>
                                </li>
                                {detailData ? (detailData.data.leadCreditScore || (detailData.data.leadCreditScore && detailData.data.leadCreditKudos))? <li>
                                   <p className="textItem">
                                      Your Credit Report will be delivered to you on screen in a PDF format.
                                   </p>
                               </li>:null :''}
                               {detailData ? (detailData.data.leadCreditScore || (detailData.data.leadCreditScore && detailData.data.leadCreditKudos))? <li>
                                    <p className="textItem">
                                        Your will also recieve an email shortly requesting you to set up your log in
                                        details to your <strong>Customers Portal</strong> enabling you to view your secured Credit Report in PDF format and a Digital format.
                                    </p>
                                </li>:<li>
                                    <p className="textItem">
                                        Your will also recieve an email shortly requesting you to set up your log in
                                        details to your <strong>Customers Portal</strong> enabling you to view your secured bank statement in pdf format.
                                    </p>
                                </li>:''}

                                <li>
                                    <p className="textItem">
                                        After pressing the <span style={{ color: "#1acbb1",fontWeight:"600" }}>Proceed</span> button Please close the window by clicking the cross on the window tab.
                                    </p>
                                </li>

                            </ul>
                        </div>:<div className="heading">
                            <ul>
                                <li>
                                    <p className="textItem">
                                    Your adviser will be informed and you will be able to talk to them for another opportunity to repeat these questions
                                    </p>
                                </li>
                                <li>
                                    <p className="textItem">
                                    Your adviser will be informed and you will be able to talk to them for another opportunity to repeat process.
                                    </p>
                                </li>
                                <li>
                                    <p className="textItem">
                                     After pressing the <span style={{ color: "#1acbb1",fontWeight:"600" }}>Proceed</span> button please close the window by clicking the cross on the window tab.
                                    </p>
                                </li>
                            </ul>    
                        </div>}
                        
                        
                        

                        <div className="heading">
                            <p className="text" style={{ marginTop: "0px" }}>
                                Your advisor will be in touch. Thank you for using Credit Assess.
                            </p>
                        </div>

                        <div className="heading">
                            {/* <button className="button" onClick={() => handleClick()}> */}
                            {status?
                            <button className="button" onClick={detailData && (detailData.data.leadCreditScore || (detailData.data.leadCreditScore && detailData.data.leadCreditKudos))?handleClick : handleMsg}>
                            Proceed
                            </button>
                            :
                            <button className="button" onClick={handleMsg}>
                                Proceed
                            </button>
                            }
                            
                        </div>
                        
                        <Modal open={isModalOpen} width={600} onOk={handleOk} onCancel={handleCancel} style={{ top: 50 }} footer={[<Button key="back" onClick={handleCancel}>OK</Button>]}>
                            <p style={{ marginTop: "0px", marginBottom: "10px" }}>
                                This section contains three tabs. These tabs contain the following information and requirements.
                            </p>

                            <p style={{ marginTop: "0px", marginBottom: "10px" }}>
                                This section contains three tabs. These tabs contain the following information and requirements.
                            </p>

                        </Modal>
                    </div>
                </div>

            </div>

            <div style={{ position: "fixed", bottom: "-8px", width: "100%" }}>
                <p style={{ display: "flex", justifyContent: "center", color: "#7b7b7b", fontSize: "14px",fontFamily:'Arial' }}>
                    Powered by Click2Check
                </p>
            </div>

            {loader &&
                <div className="loader" >
                    <RotatingLines
                        strokeColor="black"
                        strokeWidth="3"
                        animationDuration="1.75"
                        width="96"
                        visible={true}
                    />
                    <p> <strong>Please wait...</strong></p>
                </div>
            }
            {proceedMsg?<div aria-live="polite" aria-atomic="true" style={{position: "relative", minHeight:" 200px"}}>
                <div className="toast border-success message" style={{position: "absolute", top: "25%", right: "30px", opacity:"1"}}>
                    <div className="toast-header text-success" style={{height:"50px"}}>
                    Email has been sent 
                    </div>
                </div>
                </div>:null}
        </div>
    )
}