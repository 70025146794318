import React, { useState } from "react";
import { Checkbox, Row, Col } from 'antd';
import "./TermsConditions.scss";

export default function TermsConditions({ onCheckbox, CheckClickNext }) {

    const [checked, setChecked] = useState(false);

    const onChange = (event) => {
        setChecked(event.target.checked);
        onCheckbox(event.target.checked);
    }

    const handleClickNext = () => {
        CheckClickNext("3");
    }

    return (
        <>
            <div className="c2c_termscondition">

                <h3 className="title">
                    Terms & Conditions and Privacy Policy
                </h3>

                <h4 className="subTitle">
                    Click2Check Terms & Conditions & Privacy Policy
                </h4>

                <h4 className="subTitle">
                    Important: You should read all of these conditions carefully
                </h4>

                <ol className="ordered_list">
                    <li className="list-number">
                        General
                        <ol>
                            <li className="list-number">
                                In these terms and conditions and Privacy Policy ("these Conditions" or "the Conditions" or "Conditions") the following expressions shall have the following meanings: - "Click2Check" means or refers to Click2Check Ltd, (company number 09929355) and who supplies or facilitates the supply of the Services (as defined below) to you (in each case subject to these Conditions); " "Click2Check Website" means any website operated by Click2Check and through which you may access any Services (as defined below); "Services" means any services which Click2Check may supply or facilitate the supply of to you, whether directly from the Click2Check Website or via any third party, including any Intermediary; "Information" means the information supplied by Click2Check as part of the Services; "Contract" means the contract between you and Click2Check for the supply of the Information made subject to these Conditions; "Intermediary" means a third party who you may authorise to access the Services on your behalf; "Special Terms" means the terms and conditions annexed and which shall apply to Specific Services as set out in Clause 2 below. "Specific Services" means any specific services made available as part of the Services and as set out in Clause 2 below.
                            </li>

                            <li className="list-number">
                                In these Conditions, the term "you" shall mean the person receiving the Services and the term "your" shall be construed accordingly. If more than one person is receiving the Services, then the terms "you" and "your" shall be taken to refer to both of them, save where it is clear from such reference that it is intended to refer to only one of them.
                            </li>

                            <li className="list-number">
                                When you submit to the provision of any Services from Click2Check, including via an Intermediary, that constitutes an offer by you to enter into a Contract with Click2Check for the provision of Information subject to these Conditions. However, a Contract will only come into existence between you and Click2Check when Click2Check provides the Information to you. Click2Check reserves the right for any reason and without explanation to decline to enter into a Contract with you.
                            </li>

                            <li className="list-number">
                                Certain Special Terms shall apply in relation to Specific Services in addition to these Conditions, as set out in Clause 2 below. The Contract and all Information provided shall be subject to these Conditions and, where applicable to any Special Terms. These Conditions and such Special Terms shall apply to the exclusion of all other terms, conditions and statements. Where you are receiving Specific Services which are governed by Special Terms, it may be that there is a conflict or a difference between the Special Terms and these Conditions. In that event, the provisions of the Special Terms will always take precedence.
                            </li>
                        </ol>
                    </li>

                    <li className="list-number">
                        Special Terms
                        <ol>
                            <li className="list-number">
                                In providing the Services to you, Click2Check will itself be utilising the services of certain third parties. In order for Click2Check to provide the Specific Services to you, you accept the Special Terms applicable to each of the Specific Services, as set out in the following provisions. You should note that more than one set of Special Terms may apply, depending on the Services provided to you:
                                <ol className="lowerabc">
                                    <li>
                                        To provide the Services comprising the obtaining of and access to a credit file the Special Terms at Annex 1 shall be applicable;
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>

                    <li className="list-number">
                        Importance of Accurate Information, Correcting Inaccurate Information and Subject Access Requests
                        <ol>
                            <li className="list-number">
                                When submitting your details to the Click2Check Solution, via any Intermediary, it is vital that you provide accurate and detailed information. Any failure to do so may result in Click2Check not being able to provide you with a complete Service
                            </li>
                            <li className="list-number">
                                If you believe any of the Information The Click2Check solution supplies to you via any Intermediary is not accurate, then you may be able to secure an amendment to it as follows.
                                <ol className="lowerabc">
                                    <li>
                                        Contact the advisory intermediary recommending the service and request an amendment to your information and that a further search is undertaken
                                    </li>
                                    <li>
                                        Refer to Annex 1 for procedure.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>

                    <li className="list-number">
                        Charges
                        <ol>
                            <li className="list-number">
                                No charges are made by Click2Check to you for the service. All charges will be met by your intermediary for undertaking the Click2Check service.
                            </li>
                        </ol>
                    </li>

                    <li className="list-number">
                        Use of your Personal Data and Consents
                        <ol>
                            <li className="list-number">
                                Click2Check will use your personal data for the following purposes:
                                <ol className="lowerabc">
                                    <li >
                                        To provide the Services to you;
                                    </li>
                                    <li >
                                        To provide the Specific Services to you;
                                    </li>
                                    <li >
                                        To keep a record of having provided the Services and Specific Services to you;
                                    </li>
                                    <li >
                                        Otherwise in accordance with any consents and permission you have given Click2Check.
                                    </li>
                                </ol>
                            </li>
                            <li className="list-number">
                                Subject to Clause 5.1, Click2Check will not use any of your personal data obtained via the provision of the Special Services for any other purposes.
                            </li>
                            <li className="list-number">
                                You agree and consent that Click2Check may do the following on your behalf (directly or via your Intermediary) and otherwise in providing the Services:
                                <ol className="lowerabc">
                                    <li >
                                        access content (including Personal Information relating to you) provided to Click2Check for the purposes of the Specific Services and pass that content to you and your Intermediary as part of the Service;
                                    </li>
                                    <li >
                                        combine and consolidate content (including Personal Information relating to you) provided to Click2Check for the purposes of different Specific Services into a single or joint report in a format of our choosing and pass that report to you and your Intermediary as part of the Service;
                                    </li>
                                    <li >
                                        If more than one person is receiving the Services, then Click2Check may combine content (including Personal Information relating to each or all of you) and may provide such content (including the report referred to above) to both or all of you and your Intermediary as part of the Service.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>

                    <li className="list-number">
                        Your use of the Information
                        <ol>
                            <li className="list-number">
                                You may only use the Information provided to you as part of the Services for your own personal use and not for the purposes of reselling it or to provide any other service to any other person.
                            </li>
                        </ol>
                    </li>

                    <li className="list-number">
                        Exclusion/Limitation of Liability
                        <ol>
                            <li className="list-number">
                                The Information Click2Check supplies to you include data supplied to Click2Check from a number of sources, including third parties over whom Click2Check has no control. Click2Check is not able to check or verify the accuracy of this data and is therefore not able to check or verify the accuracy of the Information based on such data.
                            </li>
                            <li className="list-number">
                                Click2Check does not limit or exclude its liability for death or personal injury arising from Click2Check's negligence or that of Click2Check's servants or agents or for any liability arising in fraud or for any other liability which it is prevented by law from excluding.
                            </li>
                            <li className="list-number">
                                Subject to Clause 7.2, you agree that Click2Check does not warrant that the Information is true, correct or complete and that Click2Check shall not:-
                                <ol className="lowerabc">
                                    <li>
                                        be liable in any circumstances for any loss or damage arising from any inaccuracies, faults or omissions in the Information, unless caused by Click2Check's negligence or wilful default; and
                                    </li>
                                    <li>
                                        in any circumstances (including, without limitation, if Click2Check has been negligent) be liable for any direct, indirect or consequential loss or damage at all, or for any loss of business, capital, income, profit, anticipated savings, reputation or goodwill whether caused by Click2Check, its servants or agents arising out of or in connection with the Contract or its subject matter and whether or not Click2Check has been made aware of the likelihood of such loss arising
                                    </li>
                                </ol>
                            </li>
                            <li className="list-number">
                                Subject to Clauses 7.2 and 7.3:
                                <ol className="lowerabc">
                                    <li>
                                        Click2Check's entire liability in respect of all claims arising out of or in connection with the Contract shall not exceed the amount of the Charges.
                                    </li>
                                    <li>
                                        Click2Check shall not be liable for the accuracy of, or any loss or damage arising from, any Information Click2Check supplies to you as a result of any incomplete or inaccurate data or information you supply to Click2Check or as a result of any other fault attributable to you.
                                    </li>
                                    <li>
                                        Except as expressly provided in the Contract, all other conditions, terms and warranties, express or implied by statute or otherwise are excluded to the fullest extent permitted by law.
                                    </li>
                                </ol>
                            </li>
                            <li className="list-number">
                                Each provision of this Clause 7 is to be construed as a separate limitation applying and surviving even if for any reason one or the other of the said provisions is held inapplicable or unreasonable in any circumstances, and shall remain in force notwithstanding the performance of the Contract.
                            </li>
                        </ol>
                    </li>

                    <li className="list-number">
                        Miscellaneous
                        <ol>
                            <li className="list-number">
                                You may not transfer, assign or in any way make over to any third party the benefit of the Contract or any part of it.
                            </li>
                            <li className="list-number">
                                Neither you nor Click2Check shall be liable to the other for any delay in or failure to perform any of our respective obligations under the Contract where such delay or failure is caused by any cause beyond your or Click2Check's reasonable control.
                            </li>
                            <li className="list-number">
                                If you are a consumer, nothing contained in these Conditions will affect any of your statutory rights relating to the Services which Click2Check supplies to you.
                            </li>
                            <li className="list-number">
                                If any provision of the Contract is held by any competent authority to be invalid or unenforceable in whole or in part, the validity of the other provisions of the Contract and the remainder of the provision in question shall not be affected.
                            </li>
                            <li className="list-number">
                                This Contract shall be governed by and construed in accordance with English law, and you agree to submit to the exclusive jurisdiction of the English Courts.
                            </li>
                        </ol>
                    </li>

                </ol>

                <h5 className="annex">
                    Annex 1 – Special Terms and Conditions comprising the obtaining of and access to a credit file
                </h5>

                <div>
                    <p>
                        To assist your broker when providing advice on the most suitable product for you we will use the information held at a Credit Reference agency (CRA): namely Equifax. Equifax, like all CRA’s is a company that collects personal information from a variety of sources and provides that personal information for a variety of uses (including to prospective lenders for the purposes of making credit decisions).
                    </p>

                    <p>
                        How we and Equifax will use your information is detailed in the section of the Terms and Conditions called “use of personal information” detailed below. By confirming your agreement to proceed you are confirming that we may use your information in this way.                    </p>
                </div>

                <h5 className="annex">
                    Use of Personal Information:
                </h5>

                <div>
                    <p>
                        We will undertake a search with Equifax who will provide us with publicly held data, including the electoral roll and share credit performance data and where relevant a credit score. If you have a financial associate their data may also be provided.
                    </p>

                    <p>
                        When Equifax receives a search from us they will place a “CZ” footprint on your credit report. This search will be visible only to you when requesting a copy of your credit file but not visible to any third parties and as such will have no impact on your ability to acquire credit.
                    </p>

                    <p>
                        The information which we provide to Equifax may be supplied by them to other organisations such as Fraud Prevention Agencies and used by those organisations for the purposes of checking identity, preventing fraud, tracing and collection of debt. Equifax may also use the data to undertake statistical analysis.
                    </p>

                    <p>
                        In certain instances, your data will be shared with lender(s) to validate if you are an existing customer (which may affect whether you can be accepted for one of their products) or for fraud prevention purposes. Note that the lender does not have permission to use your data for any other purpose.
                    </p>

                    <p>
                        If you choose to apply for a product, the lender will undertake their own credit check and provide you with the terms and conditions for that product.
                    </p>

                    <p>
                        You can contact any of the CRA’s to include Equifax as follows. They will charge a small statutory fee if you wish to obtain a copy of your credit report.
                    </p>
                </div>

                <div>
                    <p style={{ marginBottom: "0px" }}>
                        <strong>Equifax Ltd,</strong> Customer Care Team, PO Box 1036, Leicester LE3 4FS or call 0800 014 2955 or log on to
                    </p>
                    <a href="http://www.equifax.co.uk" target="_blank">www.equifax.co.uk</a>

                    <p style={{ marginBottom: "0px" }}>
                        <strong>Experian,</strong> Consumer Help Service, PO Box 8000, Nottingham NG80 7WF or call 0844 4818000 or log on to
                    </p>
                    <a href="http://www.experian.co.uk" target="_blank">www.experian.co.uk</a>

                    <p style={{ marginBottom: "0px" }}>
                        <strong>CallCredit,</strong> Consumer Services Team, PO Box 491, Leeds LS3 1WZ or call 0870 0601414 or log on to
                    </p>
                    <a href="http://www.callcredit.co.uk" target="_blank"> www.callcredit.co.uk</a>
                </div>

                <h5 className="annex" style={{ marginTop: "20px" }}>
                    Complaints
                </h5>

                <div>
                    <p style={{ marginTop: "0px" }}>
                        We work hard to give you the best possible service. Of course, occasionally we don't get things right the first time. If you're not happy with the service you're receiving, please tell us so that we can put things right as soon as possible. We try to make it as easy as possible for you to share your concerns with us, and we want you to be happy with how we handle them.
                    </p>

                    <p style={{ marginTop: "0px" }}>
                        If you believe there's inaccurate information on your Equifax Credit Report, here's how to get in touch with Equifax:
                    </p>
                </div>

                <h5 className="annex">
                    Email:
                </h5>

                <div>
                    <p style={{ marginTop: "0px" }}>
                        You can email us at <a href="mailto:complaints@click2check.com" target="_blank">complaints@click2check.com</a>
                    </p>

                    <p style={{ marginTop: "0px" }}>
                        If you are an existing customer, we'll be able to process your query quicker if you include your Equifax Credit Report reference number.
                    </p>
                </div>

                <h5 className="annex">
                    What happens next?
                </h5>

                <div>
                    <p style={{ marginTop: "0px" }}>
                        Your request will be sent to Equifax and they will investigate and respond to your complaint as soon as possible. Sometimes the investigation might take some time. If this happens then you will hear from Equifax within five business days of you sending your complaint, and we will explain:
                    </p>
                    <ol className="listTypeDot">
                        <li>
                            Why Equifax can't respond in full to your concerns yet
                        </li>
                        <li>
                            Who's looking into your complaint

                        </li>
                        <li>
                            When Equifax will be back in touch.
                        </li>
                    </ol>
                    <p>
                        Equifax will complete our investigation within eight weeks but normally much sooner than this. During the time that we are investigating we will keep you updated with progress. When Equifax have completed their investigations Equifax will issue a final response detailing our decision and any actions. If you are unhappy with the Equifax final response, you may be eligible to refer your complaint to the Financial Ombudsman Service.
                    </p>
                    <p>
                        If for any reason Equifax are unable to resolve your complaint within eight weeks, Equifax write to you again explaining the reasons for the delay and how you may refer your complaint to the Financial Ombudsman Service.
                    </p>
                </div>

                <h5 className="annex">
                    How to take your formal complaint further
                </h5>

                <p>
                    If Equifax have sent you our final response or it's been more than eight weeks since you made your complaint, you may be able to ask the Financial Ombudsman Service to investigate this for you. Their contact details are below.
                </p>

                <h5 className="annex">
                    Post:
                </h5>

                <div>
                    <p style={{ marginTop: "0px", marginBottom: "0px" }}>Financial Ombudsman Service</p>
                    <p style={{ marginTop: "0px", marginBottom: "0px" }}>Exchange Tower</p>
                    <p style={{ marginTop: "0px", marginBottom: "0px" }}>London</p>
                    <p style={{ marginTop: "0px", marginBottom: "0px" }}>E14 9SR T</p>
                    <p style={{ marginTop: "0px", marginBottom: "0px" }}>Telephone: <strong>0800 023 4567</strong></p>
                    <p style={{ marginTop: "0px", marginBottom: "0px" }}>Email: <a href={"mailto:complaint.info@financial-ombudsman.org.uk"} target={"_blank"} >complaint.info@financial-ombudsman.org.uk</a></p>
                    <p style={{ marginTop: "0px", marginBottom: "0px" }}>Website: <a href={"http://www.financial-ombudsman.org.uk"} target={"blank"} >http://www.financial-ombudsman.org.uk</a></p>
                    <p style={{ marginTop: "0px", marginBottom: "0px" }}>If you have any concerns about the way we handle your data, you can contact the Information Commissioner's Office. You can find their contact details here.</p>
                    <p>All complaints information copy right <a href="https://equifaxuk.custhelp.com/app/answers/detail/a_id/225" target="_blank">https://equifaxuk.custhelp.com/app/answers/detail/a_id/225</a></p>
                </div>

                <h3 className="title"><b>Click2Check Customer Privacy Policy</b></h3>

                <div>
                    <p>We promise to look after your data and conform to the requirements of GDPR. We will not sell your data to any third party, but we do need your data to supply you with what you want. So, if you are happy with our policy below please agree to our Policy Terms.</p>
                </div>

                <h5 className="annex">
                    Why should you read this document?
                </h5>

                <div>
                    <p style={{ marginTop: "0px" }}>
                        When dealing with us, we will ask you to provide us with detailed personal information relating to your existing circumstances, your financial situation and requirements. (Your Personal Data). This document is important as it allows us to explain to you what we will do with Your Personal Data, and the various rights you have in relation to Your Personal Data.
                    </p>
                </div>

                <h5 className="annex">
                    What do we mean by “Your Personal Data”?
                </h5>

                <div>
                    <p style={{ marginTop: "0px" }}>
                        Your Personal Data means any information that describes or relates to your personal circumstances. Your Personal Data may identify you directly, for example your name, address, date of birth. Your Personal Data may also identify you indirectly, for example, your employment situation, or any other information that could be associated with your identity.
                        In the context of providing you with assistance in relation to your financial requirements Your Personal Data may include:
                        <ul>
                            <li>Title, names, date of birth, gender, civil/marital status, contact details, addresses that are necessary to verify your identity </li>
                            <li>Employment and remuneration information.</li>
                            <li>Bank account details, tax information, loans and credit commitments, personal credit history, sources of income and expenditure, family circumstances </li>
                        </ul>
                    </p>
                </div>

                <h5 className="annex">
                    The basis upon which our Firm will deal with Your Personal Data
                </h5>

                <div>
                    <p style={{ marginTop: "0px", marginBottom: "0px" }}>
                        When we communicate with you about your finance requirements we do so on the basis that both parties are entering a contract for the supply of services.
                    </p>
                    <p style={{ marginTop: "0px" }}>
                        To perform that contract, and to arrange the products you require, we have the right to use Your Personal Data for the purposes detailed below.
                    </p>
                    <p style={{ marginTop: "0px" }}>
                        The data subject has given consent to the processing of their personal data for one or more specific purposes; processing is necessary for the performance of a contract to which the data subject is party or in order to take steps at the request of the data subject prior to entering into a contract; processing is necessary for compliance with a legal obligation to which the controller is subject; processing is necessary in order to protect the vital interests of the data subject; processing is necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller;
                    </p>
                    <p style={{ marginTop: "0px" }}>
                        Alternatively, either during initial communication with you or when the contract between us has come to an end for whatever reason, we have the right to use Your Personal Data provided it is in our legitimate business interest to do so and your rights are not affected. For example, we may need to respond to requests from your adviser, and our Compliance Service Provider relating to potential information we have provided to you, or to contact you to seek feedback on the service you received.
                    </p>
                    <p style={{ marginTop: "0px" }}>
                        On occasion, we will use Your Personal data for contractual responsibilities we may owe our regulator The Financial Conduct Authority, or for wider compliance with any legal or regulatory obligation to which we might be subject. In such circumstances, we would be processing Your Personal Data to meet a legal, compliance or other regulatory obligation to which we are subject.
                    </p>
                </div>

                <h5 className="annex">
                    The basis upon which we will process certain parts of Your Personal Data
                </h5>

                <div>
                    <p style={{ marginTop: "0px" }}>
                        Where you ask us to assist you in acquiring your credit file and affordability information to assist you and your adviser in acquiring lending facilities we will ask for your personal information In order to deliver to you the aforementioned information for you and your adviser as part of the advisory process you are currently going through.
                    </p>
                </div>

                <h5 className="annex">
                    How do we collect Your Personal Data?
                </h5>

                <div>
                    <p style={{ marginTop: "0px" }}>
                        We will collect and record Your Personal Data directly from you. You will usually provide this information when visiting our system at the request of your adviser in relation to your requirement for finance.
                    </p>
                    <p style={{ marginTop: "0px" }}>
                        We will obtain information from third parties, for example, credit checks, and information from your bank should you wish an assessment of affordability that has been requested by your adviser. We will only do this if we have consent from you and this consent is contained within our Terms & Conditions. With regards to electronic ID checks we would not require your consent but will inform you of how such software operates and the purpose for which it is used.
                    </p>
                </div>

                <h5 className="annex">
                    What happens to Your Personal Data when it is disclosed to us?
                </h5>

                <div>
                    <p style={{ marginTop: "0px" }}>
                        During handling Your Personal Data, we will:
                        <ul>
                            <li>
                                Record and store Your Personal Data in our computer system, email, hard drives and cloud facilities. This information can only be accessed by employees and consultants within our Firm and only when it is necessary to provide our service to you and to perform any administration tasks associated with or incidental to that service • Submit Your Personal Data to Product Providers, on-line via a secure portal. The provision of this information to a third party is essential in allowing us to progress your enquiry made by you and your adviser.
                            </li>
                            <li>
                                Use Your Personal Data for the purposes of responding to you and your adviser in relation to your requirement for a Finance product
                            </li>
                        </ul>
                    </p>
                </div>

                <h5 className="annex">
                    Sharing Your Personal Data
                </h5>

                <div>
                    <p style={{ marginTop: "0px" }}>
                        Your personal data will only be shared with you financial adviser and Equifax Plc who will provide your credit data for assessment by you and your adviser.
                    </p>
                    <p style={{ marginTop: "0px" }}>
                        Please note that this sharing of Your Personal Data does not entitle such third parties to send you marketing or promotional messages: it is shared to ensure we can adequately fulfil our responsibilities to you, and as otherwise set out in this Customer Privacy Notice.
                    </p>
                    <p style={{ marginTop: "0px" }}>
                        We do not envisage that the performance by us of our service will involve Your Personal Data being transferred outside of the European Economic Area.
                    </p>
                </div>

                <h5 className="annex">
                    Security and retention of Your Personal Data
                </h5>

                <div>
                    <p style={{ marginTop: "0px" }}>
                        Your privacy is important to us and we will keep Your Personal Data secure in accordance with our legal responsibilities. We will take reasonable steps to safeguard Your Personal Data against it being accessed unlawfully or maliciously by a third party.
                    </p>
                </div>

                <h5 className="annex">
                    Your rights in relation to Your Personal Data
                </h5>

                <div>
                    <p style={{ marginTop: "0px" }}>
                        You can request copies of Your Personal Data that is under our control - ask us to further explain how we use Your Personal Data - ask us to correct, delete or require us to restrict or stop using Your Personal Data (details as to the extent to which we can do this will be provided at the time of any such request) - ask us to send an electronic copy of Your Personal Data to another organisation should you wish - change the basis of any consent you may have provided to enable us to market to you in the future (including withdrawing any consent in its entirety)
                    </p>
                </div>

                <h5 className="annex">
                    How to contact our Firm in relation to the use of Your Personal Data
                </h5>

                <div>
                    <p style={{ marginTop: "0px" }}>
                        If you have any questions about this document, or wish to make contact to exercise any of your rights set out within it please contact: <a href="mailto:enquiry@click2check.com" target="_blank">enquiry@click2check.com</a>
                    </p>
                    <p style={{ marginTop: "0px" }}>
                        If we feel we have a legal right not to deal with your request, or to action, it in a different way to how you have requested, we will inform you of this at the time.
                    </p>
                    <p style={{ marginTop: "0px" }}>
                        You should also contact us as soon as possible on you becoming aware of any unauthorised disclosure of Your Personal Data, so that we may investigate and fulfil our own regulatory obligations.
                    </p>
                    <p style={{ marginTop: "0px" }}>
                        If you have any concerns or complaints as to how we have handled Your Personal Data you may lodge a complaint with the UK's data protection regulator, the ICO, who can be contacted through their website at https://ico.org.uk/global/contact-us/ or by writing to Information Commissioner's Office, Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF
                    </p>
                </div>

                <h5 className="annex">
                    Confirmation
                </h5>

                <div>
                    <p style={{ marginTop: "0px" }}>
                        I hereby grant Click2Check Ltd permission to process my personal data for the purposes stated in the Privacy statement above and their Terms & Conditions.
                    </p>
                </div>

                <div style={{ display: "flex", justifyContent: "end" }}>
                    <p style={{ marginTop: "0px" }}>
                        June 2018
                    </p>
                </div>

            </div>

            <Row style={{ width: "100%"}} className="footer">
                <Col md={8} sm={8} xs={8} className="checked">
                    <Checkbox checked={checked} onChange={(e) => onChange(e)}>
                        <span className="checkboxCaption" >
                            I have read and agreed terms and conditions
                        </span>
                    </Checkbox>
                </Col>

                <Col md={8} sm={8} xs={8} style={{ display: "flex", justifyContent: "center" }}>
                    <button className="button">
                        Print
                    </button>
                </Col>

                <Col md={8} sm={8} xs={8} style={{ display: "flex", justifyContent: "end" }}>
                    <button className="button" onClick={() => handleClickNext()} disabled={!checked} style={{ cursor: !checked ? "not-allowed " : "pointer" }}>
                        Next
                    </button>
                </Col>
            </Row>

        </>
    )
}
