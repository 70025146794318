import { takeEvery } from "redux-saga/effects";
// import { UserDetails } from "./constant";

function* getUserResult() {
    let data = yield fetch("urls.baseUrl");
    data = yield data.json;
}

function* getUserDeatils() {
    // yield takeEvery(UserDetails, getUserResult)

}

export default getUserDeatils;